import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [currentCity, setCurrentCity] = useState(0);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  
  const europeanCities = [
    { name: "Paris", icon: "🗼", flag: "🇫🇷" },
    { name: "Berlin", icon: "🏛️", flag: "🇩🇪" },
    { name: "Rome", icon: "🏛️", flag: "🇮🇹" },
    { name: "Amsterdam", icon: "🚲", flag: "🇳🇱" },
    { name: "Barcelona", icon: "🏖️", flag: "🇪🇸" },
    { name: "Vienna", icon: "🎭", flag: "🇦🇹" },
    { name: "Prague", icon: "🏰", flag: "🇨🇿" },
    { name: "London", icon: "🌉", flag: "🇬🇧" }
  ];

  const features = [
    { name: 'Local Favorites', icon: 'fa-solid fa-star', description: 'Discover hidden gems' },
    { name: 'Food Tours', icon: 'fa-solid fa-utensils', description: 'Taste local cuisine' },
    { name: 'Cultural Spots', icon: 'fa-solid fa-landmark', description: 'Explore history' },
    { name: 'Nightlife', icon: 'fa-solid fa-moon', description: 'Experience the city' }
  ];

  const stats = [
    { number: "1M+", text: "Reviews", icon: "fa-solid fa-comments" },
    { number: "500K+", text: "Businesses", icon: "fa-solid fa-store" },
    { number: "10M+", text: "Foodies", icon: "fa-solid fa-users" },
    { number: "20+", text: "Countries", icon: "fa-solid fa-earth-europe" }
  ];

  useEffect(() => {
    setIsVisible(true);
    
    const interval = setInterval(() => {
      setCurrentCity(prev => (prev + 1) % europeanCities.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setIsEmailValid(true);
      setShowThankYou(true);
      setEmail('');
      setTimeout(() => setShowThankYou(false), 3000);
    } else {
      setIsEmailValid(false);
    }
  };

  return (
    <div className="App">
      <div className="background-animation"></div>
      <div className={`landing-container ${isVisible ? 'visible' : ''}`}>
        <div className="logo-container">
          <i className="fa-solid fa-location-dot logo-icon"></i>
          <span className="yelp-text">yelp</span>
          <span className="eu-text">EU</span>
        </div>
        
        <div className="coming-soon-container">
          <h1 className="coming-soon">Coming Soon to Europe</h1>
          <div className="city-showcase">
            <p className="discovering-text">
              <i className="fa-solid fa-compass"></i> Discovering
            </p>
            <p className="city-text">
              {europeanCities[currentCity].flag} {europeanCities[currentCity].name} {europeanCities[currentCity].icon}
            </p>
          </div>
        </div>

        <div className="stats-container">
          {stats.map((stat, index) => (
            <div key={index} className="stat-card">
              <i className={`${stat.icon} stat-icon`}></i>
              <div className="stat-number">{stat.number}</div>
              <div className="stat-text">{stat.text}</div>
            </div>
          ))}
        </div>

        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <i className={`${feature.icon} feature-icon`}></i>
              <h3>{feature.name}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="newsletter-container">
          <h2><i className="fa-solid fa-envelope"></i> Be the First to Know</h2>
          <form onSubmit={handleSubmit} className="newsletter-form">
            <div className="input-container">
              <input 
                type="email" 
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={!isEmailValid ? 'invalid' : ''}
              />
              <button type="submit" className="notify-btn">
                <i className="fa-solid fa-paper-plane"></i> Notify Me
              </button>
            </div>
            {!isEmailValid && <p className="error-message">Please enter a valid email</p>}
            {showThankYou && <p className="success-message">Thank you for subscribing!</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;
